// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-tsx": () => import("./../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-all-tsx": () => import("./../src/pages/all.tsx" /* webpackChunkName: "component---src-pages-all-tsx" */),
  "component---src-pages-covid-19-tsx": () => import("./../src/pages/covid-19.tsx" /* webpackChunkName: "component---src-pages-covid-19-tsx" */),
  "component---src-pages-edu-tsx": () => import("./../src/pages/edu.tsx" /* webpackChunkName: "component---src-pages-edu-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-submit-tsx": () => import("./../src/pages/submit.tsx" /* webpackChunkName: "component---src-pages-submit-tsx" */),
  "component---src-pages-work-tsx": () => import("./../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

